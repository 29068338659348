<template>
  <div class="stepContent commonClass">
    <div class="flex relative">
      <h3 class="titHead">动物检疫</h3>
      <div class="export flex">
        <el-button size="small" plain @click="exportExcel">导出</el-button>
        <el-button size="small" plain @click="returnDetail">返回</el-button>
      </div>
    </div>
    <div class="detail">
      <table class="table">
        <tr>
          <th colspan="2">动物品系</th>
          <td>{{ activeInfo.strain }}</td>
          <th colspan="2">实验编号</th>
          <td>{{ number }}</td>
          <th>实验负责人</th>
          <td>{{ dirNickname }}</td>
          <th>入驻日期</th>
          <td>
            {{ activeInfo.inTime | formatDay }}
          </td>
        </tr>
        <tr>
          <th colspan="4">供应商</th>
          <td colspan="6"> {{ activeInfo.aniSupply.supplier ? activeInfo.aniSupply.supplier : '-' }}</td>
        </tr>
        <tr>
          <td colspan="10">合格动物</td>
        </tr>
        <tr>
          <td colspan="2">耳号</td>
          <td colspan="2">笼架编号</td>
          <td colspan="2">笼舍编号</td>
          <td>性别</td>
          <td>出生日期</td>
          <td>数量</td>
          <td colspan="2">观察状态</td>
        </tr>
        <tr v-for="(item,index) in qualified" :key="index">
          <td colspan="2">{{ item.overbit }}</td>
          <td colspan="2">{{ item.frameNo }}</td>
          <td colspan="2">{{ item.cageNo }}</td>
          <td> {{ item.gender == 0 ? '♀' : '♂' }}</td>
          <td>{{ item.birthDateTime }}</td>
          <td colspan="3">{{ item.symptom }}</td>
        </tr>
        <tr>
          <td colspan="4">处理方法</td>
          <td colspan="6">{{ activeInfo.aniQuar.quaHandle }}</td>
        </tr>
        <tr>
          <td colspan="10">不合格动物</td>
        </tr>
        <tr>
          <td colspan="2">耳号</td>
          <td colspan="2">笼架编号</td>
          <td colspan="2">笼舍编号</td>
          <td>性别</td>
          <td>数量</td>
          <td>出生日期</td>
          <td colspan="2">观察状态</td>
        </tr>
        <tr v-for="(item,index) in unqualified" :key="item.id">
          <td colspan="2">{{ item.overbit }}</td>
          <td colspan="2">{{ item.frameNo }}</td>
          <td colspan="2">{{ item.cageNo }}</td>
          <td> {{ item.gender == 0 ? '♀' : '♂' }}</td>
          <td>{{ item.birthDateTime }}</td>
          <td colspan="3">{{ item.symptom }}</td>
        </tr>
        <tr>
          <td colspan="4">处理方法</td>
          <td colspan="6">{{ activeInfo.aniQuar.unquaHandle }}</td>
        </tr>
        <tr>
          <td colspan="3">检疫人</td>
          <td colspan="3">{{ activeInfo.aniQuar.applyerName }}</td>
          <td colspan="2">检疫时间</td>
          <td colspan="2">{{ activeInfo.aniQuar.applyTime | formatDay }}</td>
        </tr>
        <tr v-if="activeInfo.aniQuar.state==2">
          <td colspan="3">复核人</td>
          <td colspan="3">{{ activeInfo.aniQuar.reviewerName }}</td>
          <td colspan="2">复核时间</td>
          <td colspan="2">{{ activeInfo.aniQuar.reviewTime  | formatDay }}</td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
import FileSaver from "file-saver";
import moment from "moment";

import XLSX from "xlsx";

export default {
  name: "quarantineFinish",
  props: ['id'],
  data() {
    return {
      number: "",
      dirNickname: "",
      activeInfo: {
        aniSupply: {},
        aniQuar: {}
      },
      unqualified: [],
      qualified: [],
      typeList: []
    }
  },
  mounted() {
    this.getQuarantine()
  },
  methods: {
    getQuarantine() {
      this.$get("/subject/quar/" + this.id).then(res => {
        if (res.status == 200) {
          // 基本信息
          this.number = res.data.number
          this.dirNickname = res.data.dirNickname
          this.activeInfo = res.data.aniOrder

          res.data.aniOrder.quarResultList.forEach(obj => {
            // 筛选合格不合格
            if (obj.isQualify == 0) {
              this.unqualified.push(obj)
            } else {
              this.qualified.push(obj)
            }
          })
        }
      })
    },
    returnDetail() {
      this.$emit('changeStatus', true, 1)
    },
    // 导出
    exportExcel() {
      let name = moment(new Date()).format('YYYY-MM-DD') + '动物检疫表';
      let wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
